import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './main.css';

const InstructorDashboard = () => {
    const [examAnswers, setExamAnswers] = useState([]);
    const [scores, setScores] = useState({}); // New state for scores
    const instructorIdFromLocalStorage = localStorage.getItem('userId');
    const [confirmationMessage, setConfirmationMessage] = useState('');

    useEffect(() => {
        axios.get('https://sxs3330.uta.cloud/wdm/exam_answers.php')
            .then(response => {
                const filteredAnswers = response.data.answers.filter(answer => answer.instructor_id === instructorIdFromLocalStorage);
                setExamAnswers(filteredAnswers);
                // Initialize scores state
                const initialScores = {};
                filteredAnswers.forEach(answer => {
                    initialScores[answer.answer_id] = answer.marks_awarded || '';
                });
                setScores(initialScores);
            })
            .catch(error => {
                console.error('There was an error:', error);
            });
    }, [instructorIdFromLocalStorage]);

    const handleGradeSubmit = (answerId) => {
        const score = scores[answerId];
        axios.post('https://sxs3330.uta.cloud/wdm/exam_answers.php', { answer_id: answerId, marks_awarded: score, action: "updateMarks" })
            .then(() => {
                setConfirmationMessage(`Score updated successfully for Answer ID: ${answerId}`);
                setTimeout(() => setConfirmationMessage(''), 3000); // Clear message after 3 seconds
            })
            .catch(error => {
                console.error('Error submitting grade:', error);
                setConfirmationMessage('Failed to update score.');
                setTimeout(() => setConfirmationMessage(''), 3000); // Clear message after 3 seconds
            });
    };

    const handleScoreChange = (answerId, newScore) => {
        setScores({ ...scores, [answerId]: newScore });
    };


    return (
        <>
            <nav id="top-navbar">
                <h1>Instructor Dashboard</h1>
            </nav>

            <div id="wrapper">
                <div id="sidebar">
                    <ul>
                        <li><a href="/instructor-dashboard"><i className="fas fa-home"></i> Home</a></li>
                        <li><a href="/instructor-students"><i className="fas fa-user"></i> Students</a></li>
                        <li><a href="/instructor-grades"><i className="fas fa-clipboard-list"></i> Grades</a></li>
                        <li><a href="/instructor-exams"><i className="fas fa-pen"></i> Exams</a></li>
                        <li><a href="/instructor-course-management"><i className="fas fa-book-open"></i> Course Management</a></li>
                        <li><a href="/messages"><i className="fas fa-envelope"></i> Messages</a></li>
                        <li><a href="/ai-tutor"><i className="fas fa-envelope"></i> AI-Tutor</a></li>
                        <li><a href="/homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>

                <main id="main-content">
                    {/* Other content */}
                    <h1>Exam Answers</h1>
                    {confirmationMessage && <div className="confirmation-message">{confirmationMessage}</div>}
                    <table>
                        <thead>
                            <tr>
                                <th>index</th>
                                <th>Student Id</th>                                <th>Exam Id</th>
                                <th>Questions</th>
                                <th>Answers</th>
                                <th>Score</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {examAnswers.map((answer, index) => (
                                <tr key={answer.answer_id}>
                                    <td>{index + 1}</td>
                                    <td>{answer.student_id}</td>
                                    <td>{answer.exam_id}</td>
                                    <td>{answer.question_text}</td>
                                    <td>{answer.answer_text}</td>
                                    <td>
                                        <input
                                            type="number"
                                            value={scores[answer.answer_id] || ''}
                                            onChange={(e) => handleScoreChange(answer.answer_id, e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => handleGradeSubmit(answer.answer_id)}>Grade</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </main>
            </div>
        </>
    );
};

export default InstructorDashboard;
