import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const WriteExam = () => {
    const [examDetails, setExamDetails] = useState(null);
    const location = useLocation(); // Hook to get access to the location object

    const searchParams = new URLSearchParams(location.search);

    const exam_id = searchParams.get('exam_id'); // Get exam_id from query parameters
    const [answers, setAnswers] = useState({});
    const userId = localStorage.getItem('userId');
    const examIdNum = Number(exam_id); // Convert exam_id to number
    const [instructorId, setInstructorId] = useState(null);

    const handleBackButtonClick = () => {
        // Go back to the previous page
        window.history.back();
    };
    useEffect(() => {

        axios.post('https://sxs3330.uta.cloud/wdm/Exams.php', { action: 'getExamsForStudent', userId: userId })
            .then(response => {

                console.log("Exam ID from URL:", exam_id); // Debugging line
                const exam = response.data.exams.find(e => e.exam_id === exam_id);
                console.log("Filtered Exam:", exam); // Debugging line
                setExamDetails(exam);
                setInstructorId(exam.instructor_id);

            })
            .catch(error => {
                console.error('Error fetching exams:', error);
            });
    }, [exam_id, userId]);
    const handleAnswerChange = (questionId, answer) => {
        setAnswers(prev => ({ ...prev, [questionId]: answer }));
    };


    const handleSubmit = () => {
        if (examDetails && examDetails.questions) {
            const questionTexts = examDetails.questions.split('?, ');
            const answerData = questionTexts.map((question, index) => ({
                student_id: userId,
                exam_id: examIdNum,
                question_text: question,
                answer_text: answers[index] || '',
                instructor_id: instructorId
            }));

            axios.post('https://sxs3330.uta.cloud/wdm/exam_answers.php', {
                action: 'submitAnswers',
                answers: answerData
            })
                .then(response => {
                    console.log('Answers submitted:', response.data);
                    // Handle successful submission
                })
                .catch(error => {
                    console.error('Error submitting answers:', error);
                    // Handle errors
                });
        }
    };


    return (
        <div>
            <button onClick={handleBackButtonClick}>Go Back</button>

            {examDetails ? (
                <>
                    <h2>{examDetails.exam_title}</h2>
                    {examDetails.questions && examDetails.questions.split('?, ').map((question, index) => (
                        <div key={index}>
                            <p>{question}</p>
                            <input type="text" onChange={(e) => handleAnswerChange(index, e.target.value)} />
                        </div>
                    ))}
                    <button onClick={handleSubmit}>Submit Exam</button>
                </>
            ) : (
                <p>Loading exam details...</p>
            )}
        </div>
    );
};

export default WriteExam;
