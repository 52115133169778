import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './main.css';

const StudentGrades = () => {
    const [examAnswers, setExamAnswers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://sxs3330.uta.cloud/wdm/exam_answers.php');
                if (response.data && response.data.answers) {
                    const userId = localStorage.getItem('userId');
                    const filteredAnswers = response.data.answers.filter((answer) => answer.student_id === userId);
                    setExamAnswers(filteredAnswers);
                }
            } catch (error) {
                console.error('Error fetching exam answers:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <nav id="top-navbar">
                <h1>Grades</h1>
            </nav>
            <div id="wrapper">
                <div id="sidebar">
                    <ul>
                        <li><a href="/student-dashboard"><i className="fas fa-home"></i> Home</a></li>
                        <li><a href="/course-catalog"><i className="fas fa-book"></i> Courses Catalog</a></li>
                        <li><a href="/student-subjects"><i className="fas fa-book"></i> Enrolled Courses</a></li>                        <li><a href="/student-grades"><i className="fas fa-star"></i> Grades</a></li>
                        <li><a href="/student-exams"><i className="fas fa-file-alt"></i> Exams & Assessments</a></li>
                        <li><a href="/messages"><i className="fas fa-envelope"></i> Messages</a></li>
                        <li><a href="/ai-tutor"><i className="fas fa-envelope"></i> AI-Tutor</a></li>                        <li><a href="/homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>
                <main id="main-content">
                    <div id="grade-header">
                        <h1>Your Exam Answers</h1>
                    </div>

                    <table id="grade-table">
                        <thead>
                            <tr>
                                <th>Exam ID</th>
                                <th>Question</th>
                                <th>Your Answer</th>
                                <th>Marks Awarded</th>
                                <th>Submission Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="5">Loading...</td>
                                </tr>
                            ) : (
                                examAnswers.map((answer, index) => (
                                    <tr key={index}>
                                        <td>{answer.exam_id}</td>
                                        <td>{answer.question_text}</td>
                                        <td>{answer.answer_text}</td>
                                        <td>{answer.marks_awarded !== null ? answer.marks_awarded : 'Not Graded'}</td>
                                        <td>{answer.submission_time}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </main>
            </div>
        </div>
    );
};

export default StudentGrades;